
import mailSVG from '../images/app_mail.svg';
import otherSVG from '../images/app_other.svg';
import workSVG from '../images/app_pen.svg';
import mtgSVG from '../images/app_webmeeting.svg';
import netSearchSVG from '../images/app_webserch.svg';
import scheduleSVG from '../images/schedule.svg';
import copyFileSVG from '../images/icons/button_filecopy.svg'
import copyLinkSVG from '../images/icons/button_linkcopy.svg'

type IconList = {
  [index: string]: string;
};

/**
 * @remarks
 * svg形式の既定のアイコン
 */
const iconList: IconList = {
  copyFile: copyFileSVG,
  copyLink: copyLinkSVG,
  message: mailSVG,
  etc: otherSVG,
  work: workSVG,
  mtg: mtgSVG,
  netsearch: netSearchSVG,
  schedule: scheduleSVG,
};

export default iconList;
